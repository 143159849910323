<template>
  <v-navigation-drawer
    v-if="loggedIn"
    width="675px"
    v-model="searchDrawer"
    fixed
    clipped
    temporary
    right
  >
    <v-list-item>
      <v-list-item-avatar>
        <v-btn
          depressed
          icon
          @click.stop="$store.dispatch('setSearchDrawer', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>
          <v-text-field
            :placeholder="$t('Search')"
            autofocus
            clearable
            @click:clear="onSearchCleared()"
            @keyup="searchContent"
            v-model="searchValue"
            outlined
            dense
            hide-details="auto"
          ></v-text-field>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list v-if="lastChangedContent != null">
      <v-subheader v-if="searchedValues && lastChangedContent.length > 0">{{
        $t("Search results")
      }}</v-subheader>
      <v-subheader
        v-else-if="searchedValues && lastChangedContent.length == 0"
        >{{ $t("No results") }}</v-subheader
      >
      <v-subheader v-else>{{ $t("Recent changes") }}</v-subheader>
      <v-list-item
        v-for="content in lastChangedContent"
        :key="content._id.$oid"
        link
        :to="
          content.PageRepeatingContent
            ? {
                name: 'editPageAddEditItem',
                params: {
                  id: content.PageId.$oid,
                  itemId: content._id.$oid,
                },
              }
            : { name: 'editPage', params: { id: content.PageId.$oid } }
        "
      >
        <v-list-item-avatar>
          <v-icon>{{
            content.PageRepeatingContent
              ? "mdi-format-list-bulleted"
              : "mdi-text-long"
          }}</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ content.DisplayLabel }}
          </v-list-item-title>
          <v-list-item-subtitle
            v-text="
              content.WebsiteName +
              (content.WebsiteName == '' ? '' : ' - ') +
              content.PageName[currentLanguage]
            "
          >
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      loggedIn: this.$auth.loggedIn(),
      lastChangedContent: null,
      searchValue: "",
      searchedValues: false,
      searchTimeout: null,
    };
  },
  computed: {
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
    searchDrawer: {
      get() {
        return this.$store.getters.searchDrawer;
      },
      set(value) {
        if (value) {
          window.scrollTo(0, 0);
          this.getLastChangedContent();
        }
        this.$store.dispatch("setSearchDrawer", value);
      },
    },
  },
  methods: {
    searchContent() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.$request.get(
          "/search/?searchValue=" + this.searchValue,
          null,
          (res) => {
            this.searchedValues = true;
            this.lastChangedContent = null;

            if (res.success) {
              this.lastChangedContent = res.data;
            } else {
              const toaster = {
                message: res.message,
                timeout: false,
                type: "ERROR",
                toastClassName: ["toaster-color-error", "toaster-layout"],
                bodyClassName: ["toaster-text-layout"],
                icon: "mdi-alert-octagon",
              };
              this.$store.dispatch("setToaster", toaster);
            }
          }
        );

        clearTimeout(this.searchTimeout);
      }, 800);
    },

    onSearchCleared() {
      this.getLastChangedContent();
    },

    getLastChangedContent() {
      this.searchValue = "";
      this.lastChangedContent = null;

      this.$request.get("/lastchangedcontent", null, (res) => {
        this.searchedValues = false;
        if (res.success) {
          this.lastChangedContent = res.data;
        } else {
          const toaster = {
            message: res.message,
            timeout: false,
            type: "ERROR",
            toastClassName: ["toaster-color-error", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-alert-octagon",
          };
          this.$store.dispatch("setToaster", toaster);
        }
      });
    },
  },
};
</script>

<style>
</style>