var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loggedIn)?_c('v-navigation-drawer',{attrs:{"width":"675px","fixed":"","clipped":"","temporary":"","right":""},model:{value:(_vm.searchDrawer),callback:function ($$v) {_vm.searchDrawer=$$v},expression:"searchDrawer"}},[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-btn',{attrs:{"depressed":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$store.dispatch('setSearchDrawer', false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-text-field',{attrs:{"placeholder":_vm.$t('Search'),"autofocus":"","clearable":"","outlined":"","dense":"","hide-details":"auto"},on:{"click:clear":function($event){return _vm.onSearchCleared()},"keyup":_vm.searchContent},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}})],1)],1)],1),_c('v-divider'),(_vm.lastChangedContent != null)?_c('v-list',[(_vm.searchedValues && _vm.lastChangedContent.length > 0)?_c('v-subheader',[_vm._v(_vm._s(_vm.$t("Search results")))]):(_vm.searchedValues && _vm.lastChangedContent.length == 0)?_c('v-subheader',[_vm._v(_vm._s(_vm.$t("No results")))]):_c('v-subheader',[_vm._v(_vm._s(_vm.$t("Recent changes")))]),_vm._l((_vm.lastChangedContent),function(content){return _c('v-list-item',{key:content._id.$oid,attrs:{"link":"","to":content.PageRepeatingContent
          ? {
              name: 'editPageAddEditItem',
              params: {
                id: content.PageId.$oid,
                itemId: content._id.$oid,
              },
            }
          : { name: 'editPage', params: { id: content.PageId.$oid } }}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v(_vm._s(content.PageRepeatingContent ? "mdi-format-list-bulleted" : "mdi-text-long"))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(content.DisplayLabel)+" ")]),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(
            content.WebsiteName +
            (content.WebsiteName == '' ? '' : ' - ') +
            content.PageName[_vm.currentLanguage]
          )}})],1)],1)})],2):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }